import React from 'react'
import PropTypes from 'prop-types'

const railSoftware = (
  <p className="text-center">
    <i>
      Prices are to be used as a general idea of the cost of a layout build and are auto populated,
      and layout designed from{' '}
      <a className="text-link" href="https://www.railmodeller.com/">
        RailModeller Pro
      </a>
      .
    </i>
  </p>
)

const Disclaimer = ({ brand }) => {
  switch (brand.toLowerCase().trim()) {
    case 'fastrack':
      return (
        <>
          {railSoftware}
          <p className="text-center">
            <i>
              If the layout uses switches, by default, we use command switches so pricing could be
              substituted for manual switches. If you're in the market for track there are several
              sites that do run site discounts or track can be sourced used.
            </i>
          </p>
        </>
      )
    default:
      return railSoftware
  }
}

Disclaimer.propTypes = {
  brand: PropTypes.string,
}

export default Disclaimer
