import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'

const BtnDownload = ({ file }) => {
  const { origin } = useLocation()

  return (
    <div className="col text-center my-5">
      {file === 0 ? (
        <button type="button" className="btn btn-lg btn-download" disabled>
          Files Coming Soon
        </button>
      ) : (
        <a
          href={`${origin}${file}`}
          className="btn btn-lg btn-download"
          role="button"
          download
          rel="noreferrer"
          target="_blank"
        >
          Source Files
        </a>
      )}
    </div>
  )
}

BtnDownload.propTypes = {
  file: PropTypes.string,
}

BtnDownload.propTypes = {
  file: PropTypes.string,
}

export default BtnDownload
