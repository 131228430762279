import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

// React Icons
import { FaExternalLinkAlt } from 'react-icons/fa'

// JSON
import trackJson from '../../../data/track.json'

const handleTrackBrand = id => {
  let name
  trackJson.map(track =>
    track.id.toString() === id.toString().trim().toLowerCase() ? (name = track.name) : '',
  )
  return name
}

const handleTrackTable = (key, value) => (
  <tr>
    <td>{key}</td>
    <td>{value}</td>
  </tr>
)

const handleBoolean = value => (value === true ? 'Yes' : 'No')

const TrackSpecs = ({ data }) => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          social {
            github
          }
        }
      }
    }
  `)
  const github = site?.siteMetadata?.social?.github

  return (
    <>
      <h3>Specs</h3>
      <div className="track-specs">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {data.trackBrand && handleTrackTable('Track Brand', handleTrackBrand(data.trackBrand))}
            {data.width && handleTrackTable('Width', `${data.width} ft`)}
            {data.height && handleTrackTable('Height', `${data.height} ft`)}
            {data.mainLines && handleTrackTable('Main Lines', `${data.mainLines}`)}
            {handleTrackTable('Sidings', `${data.siding}`)}
            {data.smallestCurve &&
              handleTrackTable('Smallest Curve', `0${data.smallestCurve} curves`)}
            {data.switchCount &&
              handleTrackTable(
                'Switch Count',
                `${data.switchCount} switch${data.switchCount > 1 ? 'es' : ''}`,
              )}
            {handleTrackTable('Crossovers', handleBoolean(data.crossover))}
            {handleTrackTable('Elevated', handleBoolean(data.elevated))}
            {data.repo &&
              handleTrackTable(
                'Github',
                <>
                  <a
                    className="github-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${github}/${data.repo}`}
                  >
                    Repository
                  </a>
                  <span className="external-icon">
                    <FaExternalLinkAlt />
                  </span>
                </>,
              )}
          </tbody>
        </table>
      </div>
    </>
  )
}

TrackSpecs.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TrackSpecs
