import React from 'react'
import PropTypes from 'prop-types'

const TrackDesc = ({ desc }) => <p>{desc}</p>

TrackDesc.propTypes = {
  desc: PropTypes.string,
}

export default TrackDesc
