import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import PropTypes from 'prop-types'

// Components
import TrackLayout from '../components/Layout/TrackLayout'
import TrackImage from '../components/Track/TrackImage'
import TrackDesc from '../components/Track/TrackDesc'
import TrackSpecs from '../components/Track/TrackSpecs'
import BtnDownload from '../components/Buttons/BtnDownload'
import Disclaimer from '../components/Track/Disclaimer'

const SingleSmall = ({ location, data }) => {
  const fm = data?.mdx?.frontmatter
  const body = data?.mdx?.body

  const zip = fm.zip === null ? 0 : fm.zip.publicURL

  return (
    <TrackLayout location={location} seo={fm}>
      {fm.featureImg !== null && (
        <div className="mb-5">
          <TrackImage title={fm.title} img={fm.featureImg} />
        </div>
      )}
      <div className="track-post-content">
        {fm.description !== null && fm.description !== '' && <TrackDesc desc={fm.description} />}
        <TrackSpecs data={fm} />
        <MDXRenderer>{body}</MDXRenderer>
        <Disclaimer brand={fm.trackBrand} />

        <BtnDownload file={zip} />
      </div>
    </TrackLayout>
  )
}

SingleSmall.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default SingleSmall

export const singleSmallQuery = graphql`
  query singleSmallQuery($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        smallestCurve
        description
        title
        slug
        featureImg {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        keywords
        repo
        crossover
        switchCount
        trackBrand
        width
        height
        elevated
        siding
        mainLines
        zip {
          publicURL
        }
      }
    }
  }
`
